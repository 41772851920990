
import { BaseIndexedDb } from "../../helpers/base-indexed-db/base-indexed-db";
import { TIndexedDbStore } from "../../helpers/base-indexed-db/base-indexed-db-types";
import { TDataCacheDB } from "./data-cache-db.types";

export class DataCacheDbController extends BaseIndexedDb<TDataCacheDB, TDataCacheDB> {

    constructor() {
        super('RobocodeDataCache' ,'data-cache', 'id');
        return this;
    }

    public override async readDb(key: string): Promise<TDataCacheDB> {
        let store: TIndexedDbStore = await this.openDb();
        let groups: TDataCacheDB = {} as TDataCacheDB;
        try {
            groups = (await store.get(key)) || {}  as TDataCacheDB;
        } catch (error) {
            console.error(error);
        }
        return groups;
    }

    public override async writeDb(dataCache: any, id: string): Promise<void> {
        let data: TDataCacheDB = {
            id: id,
            text: JSON.stringify(dataCache),
            created: new Date().getTime(),
        };
        let store: TIndexedDbStore = await this.openDb();
        try {
             await store.put(data);
        } catch (error) {
            console.error(error);
        }
    }
}
